import React from 'react';
import { Sun, Umbrella, PalmtreeIcon, Plane } from 'lucide-react';

const Sparkle = ({ style }) => (
  <div 
    className="absolute w-3 h-3 rounded-full animate-twinkle"
    style={{
      backgroundColor: `hsl(${Math.random() * 360}, 100%, 75%)`,
      animationDelay: `${Math.random() * 2}s`,
      ...style
    }}
  />
);

const VacationAnnouncementSlide = () => {
  return (
    <div className="bg-gradient-to-br from-blue-400 via-purple-500 to-pink-500 min-h-screen flex flex-col justify-center items-center p-8 font-sans relative overflow-hidden">
      {[...Array(20)].map((_, i) => (
        <Sparkle key={i} style={{
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
        }} />
      ))}
      
      <h1 className="text-6xl font-extrabold text-white mb-8 p-4 rounded-full bg-white bg-opacity-20">
        Ура! Я в отпуске!
      </h1>
      
      <div className="bg-white bg-opacity-30 backdrop-blur-md rounded-xl p-8 mb-8 shadow-xl w-full max-w-md">
        <p className="text-4xl font-bold text-white mb-4 text-center">5 - 9 августа</p>
        <div className="flex justify-center space-x-8">
          <Sun className="text-yellow-300 w-16 h-16" />
          <Umbrella className="text-blue-300 w-16 h-16" />
          <PalmtreeIcon className="text-green-300 w-16 h-16" />
          <Plane className="text-indigo-300 w-16 h-16" />
        </div>
      </div>
      
      <div className="bg-white bg-opacity-30 backdrop-blur-md rounded-xl p-6 text-center mb-8 shadow-xl w-full max-w-md">
        <p className="text-2xl font-bold text-white mb-2">Буду недоступен</p>
        <p className="text-xl text-white mb-4">
          По срочным вопросам обращайтесь к моему заместителю
        </p>
        <p className="text-3xl font-extrabold text-yellow-300 animate-pulse">
          Юлия Костомарова
        </p>
      </div>
      
      <div className="text-3xl font-bold text-white">
        Хорошего мне отдыха!
      </div>
    </div>
  );
};

export default VacationAnnouncementSlide;